// import {
//   BookOpen,
//   Briefcase,
//   Calendar,
//   CheckSquare,
//   CreditCard,
//   Grid,
//   Heart,
//   Layout,
//   List,
//   Map,
//   ShoppingCart,
//   PieChart,
//   Sliders,
//   Users,
// } from "react-feather";

// const pagesSection = [
//   {
//     href: "/dashboard",
//     icon: Sliders,
//     title: "Dashboard",
//     children: [
//       {
//         href: "/dashboard/default",
//         title: "Default",
//       },
//       {
//         href: "/dashboard/analytics",
//         title: "Analytics",
//       },
//       {
//         href: "/dashboard/saas",
//         title: "SaaS",
//       },
//     ],
//   },
//   {
//     href: "/pages",
//     icon: Layout,
//     title: "Pages",
//     children: [
//       {
//         href: "/pages/profile",
//         title: "Profile",
//       },
//       {
//         href: "/pages/settings",
//         title: "Settings",
//       },
//       {
//         href: "/pages/pricing",
//         title: "Pricing",
//       },
//       {
//         href: "/pages/chat",
//         title: "Chat",
//       },
//       {
//         href: "/pages/blank",
//         title: "Blank Page",
//       },
//     ],
//   },
//   {
//     href: "/projects",
//     icon: Briefcase,
//     title: "Projects",
//     badge: "8",
//   },
//   {
//     href: "/orders",
//     icon: ShoppingCart,
//     title: "Orders",
//   },
//   {
//     href: "/invoices",
//     icon: CreditCard,
//     title: "Invoices",
//     children: [
//       {
//         href: "/invoices",
//         title: "List",
//       },
//       {
//         href: "/invoices/detail",
//         title: "Detail",
//       },
//     ],
//   },
//   {
//     href: "/tasks",
//     icon: CheckSquare,
//     title: "Tasks",
//     badge: "17",
//   },
//   {
//     href: "/calendar",
//     icon: Calendar,
//     title: "Calendar",
//   },
//   {
//     href: "/auth",
//     icon: Users,
//     title: "Auth",
//     children: [
//       {
//         href: "/auth/sign-in",
//         title: "Sign In",
//       },
//       {
//         href: "/auth/sign-up",
//         title: "Sign Up",
//       },
//       {
//         href: "/auth/reset-password",
//         title: "Reset Password",
//       },
//       {
//         href: "/auth/404",
//         title: "404 Page",
//       },
//       {
//         href: "/auth/500",
//         title: "500 Page",
//       },
//     ],
//   },
// ];

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },
//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v3.1.0",
//   },
// ];

// const navItems = [
//   {
//     title: "Pages",
//     pages: pagesSection,
//   },
//   {
//     title: "Elements",
//     pages: elementsSection,
//   },
//   {
//     title: "Material App",
//     pages: docsSection,
//   },
// ];

// export default navItems;

import {
  // BookOpen,
  Briefcase,
  // Calendar,
  // CheckSquare,
  // CreditCard,
  // Heart,
  // Layout,
  List,
  // Map,
  // ShoppingCart,
  // PieChart,
  LogOut,
  Grid,
  Sliders,
  Users,
} from "react-feather";

const mainMenu = [
  {
    href: "/  ",
    icon: List,
    title: "WedShop",
    children: [
      {
        href: "/store/dashboard",
        title: "Dashboard",
      },
      {
        href: "/store/all",
        title: "Stores",
      },
      {
        href: "/store/products",
        title: "Products",
      },
      // {
      //   href: "/store/Tracking",
      //   title: "Tracking",
      // },
      {
        href: "/store/Coupons",
        title: "Coupons",
      },
      // {
      //   href: "/store/groups",
      //   title: "Groups",
      // },
      {
        href: "/store/appointments",
        title: "Appointments",
      }
    ],
  },

  {
    href: "/lms/req/all",
    icon: Sliders,
    title: "LMS",
    children: [
      {
        href: "/lms/req/all",
        title: "Requirements",
      },
      {
        href: "/lms/planners/all",
        title: "Planners",
      },
      {
        href: "/lms/leads/all",
        title: "Leads",
      }
    ],
  },

  {
    href: "/category",
    icon: Grid,
    title: "Categories",
  },
  {
    href: "/list",
    icon: Grid,
    title: "Vendors",
  },
  {
    href: "/photographers",
    icon: Briefcase,
    title: "Photography",
    children: [
      {
        href: "/photographers/dashboard",
        title: "Dashboard",
      },
      {
        href: "/photographers",
        title: "Photographers",
      },
      {
        href: "/photographers/leads",
        title: "Leads",
      },
      // {
      //   href: "/photographers/call_requests",
      //   title: "Call Requests",
      // },
      // {
      //   href: "/photographers/message_requests",
      //   title: "Message Requests",
      // },
      {
        href: "/photographers/quotations",
        title: "Quotations",
      },
      {
        href: "/photographers/orders",
        title: "Orders",
      }
    ],
  },
  {
    href: "/entertainers",
    icon: Briefcase,
    title: "Entertainers",
    children: [
      {
        href: "/entertainers",
        title: "Entertainers",
      },
      // {
      //   href: "/entertainers/leads",
      //   title: "Leads",
      // },
      // {
      //   href: "/entertainers/quotations",
      //   title: "Quotations",
      // },
      // {
      //   href: "/entertainers/orders",
      //   title: "Orders",
      // }
    ],
  },

  {
    href: "/weddingplanner",
    icon: Briefcase,
    title: "Wedding Planner",
    children: [
      {
        href: "/wedding-planner-leads",
        title: "Vendor",
      },
    ],
  },
  {
    href: "/mehendi",
    icon: Briefcase,
    title: "Mehendi",
    children: [
      {
        href: "/mehendi-leads",
        title: "Vendor",
      },
    ],
  },
  {
    href: "/makeup",
    icon: Briefcase,
    title: "Makeup",
    children: [
      {
        href: "/makeup-leads",
        title: "Vendor",
      },
    ],
  },
  {
    href: "/category",
    icon: Grid,
    title: "Categories",
  },
  {
    href: "/users",
    icon: Users,
    title: "Users",
  },
  {
    href: "/leads",
    icon: Users,
    title: "Leads",
  },
  {
    href: "/blacklist",
    icon: Grid,
    title: "Blacklist",
  },
  {
    href: "/logout",
    icon: LogOut,
    title: "Logout",
  },
];
const navItems = [
  {
    title: "Wedly",
    pages: mainMenu,
  },
];

export default navItems;
